@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,900;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh;transform:translate(0, -15%)}.app #searchInput{border-radius:4px;line-height:30px;font-size:20px;width:400px}
main{width:100%;height:100vh;position:relative}main div:first-child{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);font-family:"Montserrat",sans-serif}.title{font-size:6rem;margin:0px;padding:0px;font-family:"Montserrat",sans-serif}@media screen and (min-width: 600px){.title{font-size:12rem}}@media screen and (min-width: 800px){.title{font-size:15rem}}h1{background-image:url(/static/media/my-min.dec3b680.jpg);background-size:auto;background-clip:text;-webkit-background-clip:text;color:transparent}h3{color:#789}h4{max-width:500px;font-weight:100px;color:#333;font-weight:300}
