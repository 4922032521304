.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translate(0, -15%);
  #searchInput {
    border-radius: 4px;
    line-height: 30px;
    font-size: 20px;
    width: 400px;
  }
}
