@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,900;1,900&display=swap");
main {
  width: 100%;
  height: 100vh;
  position: relative;
  div:first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Montserrat", sans-serif;
  }
}

.title {
  font-size: 6rem;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  @media screen and (min-width: 600px) {
    font-size: 12rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 15rem;
  }
}
h1 {
  background-image: url("./my-min.jpg");
  background-size: auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
h3 {
  color: lightslategray;
}
h4 {
  max-width: 500px;
  font-weight: 100px;
  color: #333;
  font-weight: 300;
}
